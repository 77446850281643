import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/402f9d4fd5e78d4a7977a938e69343f2/5e6fc/anatomy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3gUH/8QAFhAAAwAAAAAAAAAAAAAAAAAAABAh/9oACAEBAAEFAir/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAaEAAABwAAAAAAAAAAAAAAAAAAARARITFh/9oACAEBAAE/IZfAVE//2gAMAwEAAgADAAAAEIPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQADAQEAAAAAAAAAAAAAAREAIVExQf/aAAgBAQABPxAlVGNdwAie7vML9c//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "anatomy",
            "title": "anatomy",
            "src": "/static/402f9d4fd5e78d4a7977a938e69343f2/e5166/anatomy.jpg",
            "srcSet": ["/static/402f9d4fd5e78d4a7977a938e69343f2/f93b5/anatomy.jpg 300w", "/static/402f9d4fd5e78d4a7977a938e69343f2/b4294/anatomy.jpg 600w", "/static/402f9d4fd5e78d4a7977a938e69343f2/e5166/anatomy.jpg 1200w", "/static/402f9d4fd5e78d4a7977a938e69343f2/d9c39/anatomy.jpg 1800w", "/static/402f9d4fd5e78d4a7977a938e69343f2/df51d/anatomy.jpg 2400w", "/static/402f9d4fd5e78d4a7977a938e69343f2/5e6fc/anatomy.jpg 2432w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Uniswap is an `}<em parentName="p">{`automated liquidity protocol`}</em>{` powered by a `}<Link to="/docs/honeyswap/core-concepts/math" mdxType="Link">{`constant product formula`}</Link>{` and implemented in a system of non-upgradeable smart contracts on the `}<a parentName="p" {...{
        "href": "https://ethereum.org/"
      }}>{`Ethereum`}</a>{` blockchain. It obviates the need for trusted intermediaries, prioritizing `}<strong parentName="p">{`decentralization`}</strong>{`, `}<strong parentName="p">{`censorship resistance`}</strong>{`, and `}<strong parentName="p">{`security`}</strong>{`. Uniswap is `}<strong parentName="p">{`open-source software`}</strong>{` licensed under the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GNU_General_Public_License"
      }}>{`GPL`}</a>{`.`}</p>
    <p>{`Each Uniswap smart contract, or pair, manages a liquidity pool made up of reserves of two `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-20"
      }}>{`ERC-20`}</a>{` tokens.`}</p>
    <p>{`Anyone can become a liquidity provider for a pool by depositing an equivalent value of each underlying token in return for pool tokens. These tokens track pro-rata LP shares of the total reserves, and can be redeemed for the underlying assets at any time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/94f9a497b001a6b27df2c37adadc05b4/824f2/lp.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd6AsD//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAYEAADAQEAAAAAAAAAAAAAAAABESEAEP/aAAgBAQABPyFFw6vn/9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQEBAQEAAAAAAAAAAAAAAAERADFB/9oACAEBAAE/EGNCeiXLUjMc3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "lp",
            "title": "lp",
            "src": "/static/94f9a497b001a6b27df2c37adadc05b4/e5166/lp.jpg",
            "srcSet": ["/static/94f9a497b001a6b27df2c37adadc05b4/f93b5/lp.jpg 300w", "/static/94f9a497b001a6b27df2c37adadc05b4/b4294/lp.jpg 600w", "/static/94f9a497b001a6b27df2c37adadc05b4/e5166/lp.jpg 1200w", "/static/94f9a497b001a6b27df2c37adadc05b4/d9c39/lp.jpg 1800w", "/static/94f9a497b001a6b27df2c37adadc05b4/824f2/lp.jpg 2042w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Pairs act as automated market makers, standing ready to accept one token for the other as long as the “constant product” formula is preserved. This formula, most simply expressed as `}<inlineCode parentName="p">{`x * y = k`}</inlineCode>{`, states that trades must not change the product (`}<inlineCode parentName="p">{`k`}</inlineCode>{`) of a pair’s reserve balances (`}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{`). Because `}<inlineCode parentName="p">{`k`}</inlineCode>{` remains unchanged from the reference frame of a trade, it is often referred to as the invariant. This formula has the desirable property that larger trades (relative to reserves) execute at exponentially worse rates than smaller ones.`}</p>
    <p>{`In practice, Uniswap applies a 0.30% fee to trades, which is added to reserves. As a result, each trade actually increases `}<inlineCode parentName="p">{`k`}</inlineCode>{`. This functions as a payout to LPs, which is realized when they burn their pool tokens to withdraw their portion of total reserves. In the future, this fee may be reduced to 0.25%, with the remaining 0.05% withheld as a protocol-wide charge.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40a3fe965d188286abe8502f68ef42a1/4eea2/trade.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd6AsD//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAZEAACAwEAAAAAAAAAAAAAAAARIQABEDH/2gAIAQEAAT8hFnqjOf/aAAwDAQACAAMAAAAQ+A//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QJ//EABgQAQADAQAAAAAAAAAAAAAAAAEAETFh/9oACAEBAAE/EGmnQSKHtQyf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "trade",
            "title": "trade",
            "src": "/static/40a3fe965d188286abe8502f68ef42a1/e5166/trade.jpg",
            "srcSet": ["/static/40a3fe965d188286abe8502f68ef42a1/f93b5/trade.jpg 300w", "/static/40a3fe965d188286abe8502f68ef42a1/b4294/trade.jpg 600w", "/static/40a3fe965d188286abe8502f68ef42a1/e5166/trade.jpg 1200w", "/static/40a3fe965d188286abe8502f68ef42a1/d9c39/trade.jpg 1800w", "/static/40a3fe965d188286abe8502f68ef42a1/4eea2/trade.jpg 1953w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Because the relative price of the two pair assets can only be changed through trading, divergences between the Uniswap price and external prices create arbitrage opportunities. This mechanism ensures that Uniswap prices always trend toward the market-clearing price.`}</p>
    <h1 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Further reading`}</h1>
    <p>{`To see how token swaps work in practice, and to walk through the lifecycle of a swap, check out `}<Link to="/docs/honeyswap/core-concepts/swaps" mdxType="Link">{`Swaps`}</Link>{`. Or, to see how liquidity pools work, see `}<Link to="/docs/honeyswap/core-concepts/pools" mdxType="Link">{`Pools`}</Link>{`.`}</p>
    <p>{`Ultimately, of course, the Uniswap protocol is just smart contract code running on Ethereum. To understand how they work, head over to `}<Link to="/docs/honeyswap/protocol-overview/smart-contracts/" mdxType="Link">{`Smart Contracts`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      